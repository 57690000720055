import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import { navigate, withPrefix } from "gatsby";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { selectStoresState } from "../sagas/stores/storesSlice";

export default function PublishingStoreSucess() {
  const { selectedUserStore } = useSelector(selectStoresState);

  const handleLaunchMyStorefront = (e) => {
    e.preventDefault();

    if (selectedUserStore.shopify_store_url) {
      if (selectedUserStore.store_password && selectedUserStore.store_password !== 'na') {
        Swal.fire({
          title: 'Your shop password',
          text: selectedUserStore.store_password,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'Copy and Go'
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigator.clipboard.writeText(selectedUserStore.store_password).then(() => window.open(selectedUserStore.shopify_store_url, '_blank'));
          }
        });
      } else {
        window.open(selectedUserStore.shopify_store_url, '_blank');
      }
    }
  }

  const handleViewStores = () => {
    navigate("/my-storefronts");
  }

  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="congrats-cont">
          <div className="container">
            <div className="inner-cont">
              <div className="box">
                <h1>Congrats!</h1>
                <p>Your store is ready.</p>
                <div className="figure">
                  <img src={withPrefix("assets/img/congrats-art.png")} alt="congrats-art" />
                </div>
              </div>
              <div className="bottom-btns">
                <div className="btn-out">
                  <a href='#' onClick={handleLaunchMyStorefront} className="btn btn-blue">
                    LAUNCH MY STOREFRONT
                  </a>
                </div>
                <div className="btn-out">
                  <a href="#" onClick={handleViewStores} className="btn">
                    VIEW ALL MY STORES
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
